export type SiteConfig = typeof siteConfig
export const siteConfig = {
    name: "sprunkstard",
    slogan: "slogan",
    icon: "/favicon.ico",
    appleIcon: "/apple-touch-icon.png",
    gameIframe: "https://cocrea.world/@TrystanKyllo/IncrediboxSprunkstardIfsprunkiwereinmustard",
    domain: "sprunkstard.org",
    gaId: "G-BNBWC2YV9T",
    navbarItems: [
        {
            title: "title",
             href: "/",
        }
    ],
}
